<template>
  <div>
    <div class="header-title justify-content-between">
      <b>{{ $t("Wallet") }}</b>
    </div>

    <div class="wallet-body mt-2">
      <div
        class="d-flex justify-content-center align-items-center h-30-fixed"
        v-if="isLoading"
      >
        <b-spinner variant="success" label="Spinning"></b-spinner>
      </div>

      <div v-if="!isLoading">
        <div class="d-flex flex-column align-items-center mt-2">
          <span class="wallet-title">{{ $t("Wallet Balance") }}</span>
          <span class="wallet-balance"
            >${{ totalNetworth == null ? 0 : addCommas(totalNetworth) }}</span
          >
        </div>

        <div class="d-flex flex-column align-items-center mt-3">
          <!-- <div class="btn-pair" v-if="userType == 'reuse'">
                    <button class="btn-left" @click="popUpTransak()">Buy Crypto</button>
                    <button class="btn-right" @click="showWidgetModal()">Deposit Coin</button>
                </div> -->
          <div>
            <button class="btn-center" @click="popUpTransak()">Buy Crypto</button>
          </div>
        </div>

        <div class="wallet-container mt-3">
          <table class="table table-borderless" id="wallet-table">
            <thead>
              <th colspan="2" class="text-left">
                <b-button class="add-coin-btn" @click="showAddCoinModal">
                  <span class="mr-2">{{ $t("Coin") }}</span>
                  <inline-svg
                    :src="require('../assets/icon/new-icon/add-circle.svg')"
                    width="24px"
                    height="24px"
                    aria-label="logout"
                  ></inline-svg>
                </b-button>
              </th>
              <th class="d-none d-md-none d-lg-table-cell">{{ $t("Price") }}</th>
              <th class="d-none d-md-none d-lg-table-cell">24 {{ $t("Hour") }} (%)</th>
              <th class="d-none d-md-none d-lg-table-cell">{{ $t("Floating") }}</th>
              <th class="d-none d-md-none d-lg-table-cell">{{ $t("Withdrawable") }}</th>
              <th>{{ $t("Balance") }}</th>
            </thead>

            <tbody>
              <tr v-for="row in balances" v-bind:key="row.id">
                <router-link
                  class="bal-link"
                  :to="
                    row.click == false
                      ? ''
                      : {
                          name: 'Balance',
                          params: {
                            symbol: row.api_symbol,
                            network: row.network.toUpperCase(),
                          },
                        }
                  "
                >
                  <td colspan="2" class="coin-bal-td text-left">
                    <div class="d-flex flex-row w-100">
                      <div>
                        <img :src="row.token_image" width="40" height="40" />
                      </div>
                      <div class="coin">
                        <div>
                          <b>{{ row.token }}</b>
                        </div>
                        <div class="text-info-50 small">
                          {{ row.symbol }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="d-none d-md-none d-lg-table-cell">
                    <b>${{ row.token_price }}</b>
                  </td>
                  <td
                    :class="`d-none d-md-none d-lg-table-cell ${
                      priceChanges[row.token_price_change_percentage * 1 > 0]
                    }`"
                  >
                    <b>{{ row.token_price_change_percentage }}%</b>
                  </td>
                  <td class="d-none d-md-none d-lg-table-cell">
                    <b>{{ row.floating_balance }}</b>
                  </td>
                  <td class="d-none d-md-none d-lg-table-cell">
                    <b>{{ row.withdrawable }}</b>
                  </td>
                  <td>
                    <span
                      ><b>{{
                        row.withdrawable == null ? 0 : addCommas(row.total_balance)
                      }}</b></span
                    ><br />
                    <span id="actual_bal"
                      >≈ ${{ row.networth == null ? 0 : addCommas(row.networth) }}</span
                    >
                  </td>
                </router-link>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="!isLoading && balances.length === 0">
        <p class="text-center mt-3">{{ $t("No Wallet Found") }}</p>
      </div>

      <!-- Add Coin Modal -->
      <b-modal ref="add-coin-modal" hide-footer title="ADD COIN" centered>
        <form id="send-modal-form" class="modal-form" @submit.stop.prevent="addCoin">
          <div class="d-flex flex-column text-center">
            <div class="dropdown" v-if="coin.length !== 0">
              <button type="button" class="dropbtn w-100" @click="coinDropdown">
                <div class="dropdown-item-content">
                  <img :src="coin.image" width="30" height="30" />
                  <span class="ml-2"
                    ><strong>{{ coin.symbol }}</strong></span
                  >
                  <span class="text-light-gray small ml-2">{{ coin.name }}</span>
                </div>

                <div class="dropdown-arrow">
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
              </button>

              <div id="coin-dropdown" class="dropdown-content">
                <div
                  class="dropdown-item"
                  v-for="coin in coin_option"
                  v-bind:key="coin.api_symbol"
                  @click="coin_selected(coin)"
                >
                  <div class="dropdown-item-content">
                    <img :src="coin.image" width="30" height="30" />
                    <span class="ml-2"
                      ><strong>{{ coin.symbol }}</strong></span
                    >
                    <span class="text-gray small ml-2">{{ coin.name }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="dropdown" v-else>
              <button type="button" class="dropbtn w-100">
                <div>{{ $t("No coin to add") }}</div>
                <div class="dropdown-arrow">
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
              </button>
            </div>

            <button type="submit" class="add-coin mt-5" :disabled="coin.length === 0">
              {{ $t("Add Coin") }}
            </button>
          </div>
        </form>
      </b-modal>

      <div
        class="outside-widget"
        v-if="widgetDisplay"
        @click="closeWidgetModal()"
        tabindex="0"
      >
        <div id="widgetModal">
          <iframe
            id="widget"
            :src="widgetApi"
            title=""
            frameborder="0"
            loading="eager"
            allow="accelerometer; autoplay; camera; gyroscope; payment"
          ></iframe>
          <inline-svg
            @click="closeWidgetModal()"
            class="cancel-btn"
            :src="require('../assets/icon/cancel-white.svg')"
            fill="black"
            width="35"
            height="35"
            aria-label="cancel"
          ></inline-svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../helper/api.js";

import InlineSvg from "vue-inline-svg";
import transakSDK from "@transak/transak-sdk";

export default {
  name: "Wallet",
  components: {
    InlineSvg,
  },
  data() {
    return {
      priceChanges: {
        true: "text-success",
        false: "text-danger",
      },
      totalNetworth: 0,
      email: "",
      balances: [
        // {
        //     'token_image':require('../assets/images/ethereum.png'),
        //     'token':'Ethereum',
        //     'api_symbol':'ETH',
        //     'withdrawable':'357.0122',
        //     'networth':"456757.84",
        //     'click':false
        // },
        // {
        //     'token_image':require('../assets/images/bitcoin.png'),
        //     'token':'Bitcoin',
        //     'api_symbol':'BTC',
        //     'withdrawable':'1538.224',
        //     'networth':"29331470.52",
        //     'click':false
        // }
      ],
      widgetApi: process.env.VUE_APP_FRONTEND_URL + "/createDeposit/boo",
      coin_option: [],
      isLoading: false,
      coin: [],
      userType: localStorage.getItem("userType"),
      widgetDisplay: false,
      environment: process.env.VUE_APP_TRANSAK_ENVIRONMENT,
      apiKey: process.env.VUE_APP_TRANSAK_API_KEY,
    };
  },

  async mounted() {
    // Close the dropdown menu if the user clicks outside of it

    this.isLoading = true;
    // window.onclick = function (event) {
    //     console.log(event.target);
    //     if (!event.target.matches('.dropbtn')) {
    //             var dropdowns = document.getElementsByClassName('dropdown-content')
    //             var i
    //             for (i = 0; i < dropdowns.length; i++) {
    //             var openDropdown = dropdowns[i]
    //             if (openDropdown.classList.contains('show')) {
    //                 openDropdown.classList.remove('show')
    //             }
    //         }
    //     }
    // }

    await this.fetchBalances();
    await this.fetchCoins();

    let results = this.coin_option.filter(
      (o1) => !this.balances.some((o2) => o2.api_symbol === o1.api_symbol)
    );
    this.coin = results;
  },

  methods: {
    showAddCoinModal() {
      this.$refs["add-coin-modal"].show();

      let results = this.coin_option.filter(
        (o1) => !this.balances.some((o2) => o2.api_symbol === o1.api_symbol)
      );
      if (results.length == 0) {
        return;
      }
      this.coin_option = results;

      this.coin = {
        image: this.coin_option[0].image,
        name: this.coin_option[0].name,
        api_symbol: this.coin_option[0].api_symbol,
        network: this.coin_option[0].network.symbol.toLowerCase(),
        symbol: this.coin_option[0].symbol,
      };
    },

    async showWidgetModal() {
      this.widgetDisplay = true;
      // console.log(document.getElementById('widgetModal'))
      // document.getElementById('widgetModal').style.display = 'flex';

      // document.body.style.opacity = "0.3";
      // document.body.style.setProperty('filter', "brightness(50%)");
      // document.getElementById('widgetModal').style.setProperty('filter', "brightness(100%)", 'important');
    },

    closeWidgetModal() {
      this.widgetDisplay = false;
    },

    addCommas(nStr) {
      nStr += "";
      var x = nStr.split(".");
      var x1 = x[0];
      var x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },

    coinDropdown() {
      document.getElementById("coin-dropdown").classList.toggle("show");
    },

    coin_selected(coin) {
      this.coin = {
        image: coin.image,
        name: coin.name,
        api_symbol: coin.api_symbol,
        network: coin.network.symbol.toLowerCase(),
        symbol: coin.symbol,
      };
      // this.coin = coin
      document.getElementById("coin-dropdown").classList.remove("show");
      return this.coin;
    },

    async fetchBalances() {
      try {
        const response = await api.apiCall("core", "GET", "/deposit-summary");

        // if(this.userType == 'reuse'){
        //     // for(let i = 0; i < response.message.balance.length; i ++){
        //     //     this.balances.push(response.message.balance[i]);
        //     // }
        // }else{
        this.balances = response.message.balance;
        // console.log(this.balances)
        // }
        this.email = response.message.email;
        // this.totalNetworth = response.message.totalNetworth;

        this.totalNetworth = this.balances
          .reduce((a, b) => a + b.networth * 1, 0)
          .toFixed(2);

        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async fetchCoins() {
      try {
        const response = await api.apiCall("core", "GET", "/tokens/get-all");
        this.coin_option = response.message;
        this.coin = this.coin_option[0];
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },

    async addCoin() {
      console.log("coin", this.coin);

      try {
        const platformName = localStorage.getItem("platform_name") + 0 + 1;
        const params = {
          api_symbol: this.coin.api_symbol,
          label: [platformName],
        };

        const response = await api.apiCall(
          this.coin.network,
          "POST",
          "/addresses/create",
          params
        );

        console.log("response", response);

        if (response.status.toUpperCase() == "SUCCESS") {
          this.$swal({
            text: this.$t("Coin added successfully"),
            icon: "success",
            confirmButtonColor: "#33AD4A",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          this.$swal({
            text: this.$t("Failed to add coin"),
            icon: "error",
            confirmButtonColor: "#33AD4A",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$refs["add-child-modal"].hide();
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    },

    async popUpTransak() {
      console.log(this.environment);
      let transak = new transakSDK({
        apiKey: this.apiKey, // Your API Key
        environment: this.environment, // STAGING/PRODUCTION
        themeColor: "2575fc", // App theme color
        // defaultCryptoCurrency: 'USDT',
        // networks: 'ethereum,tron,bsc',
        email: this.email, // Your customer's email address
        //payments
        // paymentMethod: 'credit_debit_card',
        // fiatAmount: '10000',
        // fiatCurrency: 'JPY', // Null for all currencies
        // cryptoCurrencyCode: 'USDT',
        // network: 'bsc',
        // hideExchangeScreen: true,
        // disablePaymentMethods: 'credit_debit_card',

        // walletAddress: '0x7e2e8d6df682d4cb3b9b35d374c6829dd930d828',
        // disableWalletAddressForm: true,

        isFeeCalculationHidden: true,
        hideMenu: true,
        // hideExchangeScreen: true,
        // partnerOrderId: '5e2f559511a9de73d364eeaf',
        // isDisableCrypto: true,

        productsAvailed: "BUY",
        exchangeScreenTitle: "Buy Crypto Instantly",
        // redirectURL: 'https://www.youtube.com',
        hostURL: window.location.origin,
        widgetHeight: "640px",
        widgetWidth: "400px",
      });

      // console.log(transak)

      transak.init();

      console.log(document.getElementById("widgetModal"));

      // document.body.style.opacity = "0.3";
      // document.body.style.setProperty('filter', "brightness(50%)");
      document
        .getElementById("widgetModal")
        .style.setProperty("opacity", "0.5", "important");

      // To get all the events
      transak.on(transak.ALL_EVENTS, () => {
        // console.log(data)
      });

      // This will trigger when the user marks payment is made.
      transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, () => {
        // console.log(orderData);
        transak.close();
      });
      transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, () => {
        // console.log(close);
        // alert('noooo');
        // transak.close();
      });
    },
  },
};
</script>

<style scoped>
@import "../css/styles.css";

.add-coin-btn {
  background-color: var(--primary);
  border-radius: 50px;
  border: none;
  display: flex;
  align-items: center;
}

.add-coin-btn:hover {
  opacity: 0.8 !important;
  background: var(--primary) !important;
}

.add-coin-btn:focus {
  background: var(--primary) !important;
}

/* .coin-bal-td {
    width: 200px;
} */

.bal-link {
  display: table-row;
  text-align: center;
  border: none;
  background-color: transparent;
  color: var(--info);
  width: 100%;
  text-decoration: none;
  border-radius: 20px;
}

.bal-link:hover {
  color: black;
  text-decoration: none;
  background-color: var(--light);
}

.coin-btn {
  background-color: var(--primary);
  /* border: none; */
  font-weight: bold;
  border-radius: 20px;
  padding: 0px;
  width: 100px;
  color: var(--light);
  border: 1px solid var(--primary);
  height: 25px;
  padding-left: 20px;
  position: relative;
}

.coin-btn:before {
  content: "";
  display: block;
  background-color: var(--primary);
  border-radius: 100%;
  width: 35px;
  height: 35px;
  left: -4px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 31%);
  bottom: -6px;
  position: absolute;
}

.coin-btn #plus-icon {
  position: absolute;
  left: 3px;
  top: -1px;
  color: white;
}

.coin-btn:hover,
.coin-btn:focus,
.coin-btn:hover text {
  color: var(--primary);
  background-color: var(--light);
  fill: var(--light);
  border: 1px solid var(--primary);
}

.wallet-body {
  margin: 0% 10%;
  display: flex;
  flex-direction: column;
}

.wallet-title {
  font-size: 16px;
  color: var(--primary);
  text-transform: uppercase;
}

.wallet-balance {
  font-size: 50px;
  color: var(--info);
}

#wallet-table thead {
  border-bottom: 1px solid white;
  text-align: center;
}

#wallet-table th {
  font-size: 14px;
  padding: 14px;
}

#wallet-table tr {
  border-bottom: 1px solid white;
  /* color: var(--info); */
  text-align: center;
}

#wallet-table td {
  border-top: none;
  vertical-align: middle !important;
  padding: 14px;
  /* color: black; */
}

.coin {
  align-items: baseline;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  /* width: 60%; */
}

#actual_bal {
  color: var(--info-50);
  font-size: 13px;
}

/* modal */
.add-coin {
  background-color: transparent;
  border: 2px solid var(--primary);
  color: var(--primary);
  font-weight: bold;
  border-radius: 30px;
  margin-bottom: 10%;
  cursor: pointer;
  padding: 8px;
}

.add-coin:hover {
  color: var(--primary);
  background-color: var(--primary-10);
  border: 2px solid var(--primary);
}

/* Dropdown Button */
.dropbtn {
  background-color: white;
  border: 1px solid var(--secondary);
  border-radius: 20px;
  color: var(--primary);
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  align-items: center;
  z-index: 99;
}

.dropdown-arrow {
  position: absolute;
  right: 5%;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  /* position: relative; */
  /* display: inline-block; */
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--light);
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  width: 100%;
  top: 60%;
  padding-top: 30px;
  border-radius: 0px 0px 20px 20px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: var(--primary);
}

.dropdown-item-content {
  width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropdown-item:hover {
  color: var(--primary);
  background-color: var(--primary-10);
}

.dropdown-item:hover .text-gray {
  color: var(--primary);
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}

.btn-pair {
  width: 260px;
}

.btn-left {
  display: inline-block;
  width: 50%;
  font-weight: 500;
  color: var(--light);
  background-color: var(--primary);
  border: 1px solid var(--primary);
  box-shadow: 0 3px 10px rgb(0 0 0 / 31%);
  border-radius: 35px 0px 0px 35px;
}

.btn-right {
  display: inline-block;
  width: 50%;
  font-weight: 500;
  color: var(--light);
  background-color: var(--primary);
  border: 1px solid var(--primary);
  box-shadow: 0 3px 10px rgb(0 0 0 / 31%);
  border-radius: 0px 35px 35px 0px;
}

.btn-left:hover,
.btn-right:hover {
  color: var(--primary);
  background-color: var(--light);
}

.btn-center {
  display: inline-block;
  width: 100%;
  font-weight: 500;
  color: var(--light);
  background-color: var(--primary);
  border: 1px solid var(--primary);
  box-shadow: 0 3px 10px rgb(0 0 0 / 31%);
  border-radius: 10px;
  padding: 5px;
}

#widgetModal {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

#widget {
  height: 660px;
  width: 400px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  margin: auto;
  /* max-width: 480px */
}

.outside-widget {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.cancel-btn {
  /* display: flex; */
  position: absolute;
  background: white;
  border-radius: 50%;
  color: #2575fc !important;
  right: -10px;
  top: -10px;
  z-index: 10;
  cursor: pointer;
}

.coin-balance-container {
  margin-bottom: 30px;
}

.coin-balance-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
}

.coin-balance-body {
  border-bottom: 1px solid var(--info-10);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  text-decoration: none !important;
  color: var(--dark);
}

.coin-balance-body .sub-content {
  color: var(--info-50);
}

.coin-balance-body:hover {
  background-color: white;
  color: var(--dark);
}

.coin-balance-body .coin-info {
  font-size: 13px;
}

.coin-balance-body .coin-balance {
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  /* .mobile {
        display: none;
    } */

  .wallet-body {
    margin: 0% 5%;
  }

  #wallet-table {
    font-size: 14px;
  }

  #wallet-table td {
    padding: 0.2rem;
  }

  .wallet-balance {
    font-size: 45px;
  }
}

.wallet-container table tbody {
  display: block;
  overflow-y: auto;
  max-height: 450px;
  /* width: 100%; */
}

.wallet-container thead,
.wallet-container tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.wallet-container table th,
.wallet-container table td {
  text-align: left;
}
</style>
